import "./testPage.css"

const TestPage = () => {
    return (
        <div className="mainDiv">
            <div className="secondaryDiv">
                <div className="redDiv">
                    Test 1
                </div>
                <div className="redDiv">
                    Test 2
                </div>
                <div className="greenDiv">
                    Test 3
                </div>
            </div>
            <div className="secondaryDiv">div 2</div>
            <div className="secondaryDiv">div 3</div>
        </div>
    )
}

export default TestPage