import { useState } from 'react';
import './App.css';
import DBApp from './db/DBApp';
import Production from './production/Production';
import MelodyApp from './mg/MelodyApp';
import GameOfLife from './gameOfLife/gameOfLife';
import Investing from './investing/investing';
import TestPage from './testPage/testPage';

function App() {
  const testMode = false;
  const [ colorModeClass, setColorModeClass ] = useState('App-dark')
  const [ colorModeButtonLabel, setcolorModeButtonLabel ] = useState('Light Mode');
  const [ menuSelection, setMenuSelection ] = useState('home')

  const toggleColorMode = () => {
    if (colorModeClass === 'App-dark') {
      setcolorModeButtonLabel('Dark Mode');
      setColorModeClass('App-light');
    } else {
      setcolorModeButtonLabel('Light Mode');
      setColorModeClass('App-dark');
    }
  };

  const handleClick = (selection) => {
    setMenuSelection(selection)
  }

  return (
    <div className={colorModeClass}>
      <button id="color-mode-toggle" onClick={toggleColorMode}>{colorModeButtonLabel}</button>
      <header className="App-header" onClick={() => setMenuSelection('home')}>
        <h2><b>Quietman Productions</b></h2>
      </header>
      <div id="menu">
        <div className={ menuSelection === "dangMusicPlayer" ? "selected" : "unselected"} onClick={() => handleClick('dangMusicPlayer')}>Music</div>
        <div className={ menuSelection === "production" ? "selected" : "unselected"} onClick={() => handleClick('production')}>Production</div>
        <div className={ menuSelection === "newMelodyGenerator" ? "selected" : "unselected"} onClick={() => handleClick('newMelodyGenerator')}>Melody Generator</div>
        <div className={ menuSelection === "bellBox" ? "selected" : "unselected"} onClick={() => handleClick('bellBox')}>Bell Box Game</div>
        <div className={ menuSelection === "gameOfLife" ? "selected" : "unselected"} onClick={() => handleClick('gameOfLife')}>Game of Life</div>
        <div className={ menuSelection === "investing" ? "selected" : "unselected"} onClick={() => handleClick('investing')}>Investing Strategy</div>
        {testMode && <div className={ menuSelection === "testPage" ? "selected" : "unselected"} onClick={() => handleClick('testPage')}>Test Page</div>}
      </div>
      {(menuSelection === 'home') && <div id="home"><h1>A place for some of Paul's stuff.</h1></div>}
      {(menuSelection === 'dangMusicPlayer') && <DBApp />}
      {(menuSelection === 'production') && <Production />}
      {(menuSelection === 'newMelodyGenerator') && <MelodyApp {...{colorModeClass}} />}
      {(menuSelection === 'bellBox') && <iframe id="bellBoxGame" src=".\games\bell_box\bell_box.html" title="Bell Box Game"></iframe>}
      {(menuSelection === 'gameOfLife') && <GameOfLife />}
      {(menuSelection === 'investing') && <Investing />}
      {(menuSelection === 'testPage') && <TestPage />}
    </div>
  );
}

export default App;
